import './App.css';

import Banner from './Components/Banner';
// import Content from './Components/Content';
import Educativo from './Components/Educativo';
import Final from './Components/Final';
import Footer from './Components/Footer';
import Menu from './Components/Menu';
import Tipos from './Components/Tipos';

function App() {
  return (
    <div className="App">
      <Menu/>
      <Banner/>
      {/* <Content/> */}
      <Educativo/>
      <Tipos/>
      <Final/>
      <Footer/>
    </div>
  );
}

export default App;
