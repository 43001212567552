import React from "react";

import "./style.css";
import how from '../../Assets/img/how.png'
import ScrollAnimation from "react-animate-on-scroll";
function Educativo() {
  return (
    <div className="educativo_wrapper">
      <div className="educativo_container">
      <ScrollAnimation animateIn="fadeIn">
        <div className="headline_educativo">
          <div className="title_educativo">Módulo Phishing Educativo</div>
          <div className="content">
            A MAV Tecnologia desenvolveu o módulo de Phishing Educativo para que
            o administrador possa enviar campanhas pontuais para os usuários e
            educá-los sobre o perigo dos phishings e como identificá-los em seu
            e-mail.
          </div>
        </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn">
        <div className="image_container">
            <div className="title_image">como funciona</div>
            <img src={how} alt="phishing"/>
        </div>
        </ScrollAnimation>
      </div>
    </div>
  );
}

export default Educativo;
