import React ,{useEffect,} from "react";

import "./style.css";
import "./responsive.css";
// import logom from "../../Assets/img/logo.svg";
import logoo from "../../Assets/img/logo1.svg";

import $ from "jquery";

export default function Menu() {
  // const [logo, setLogo] = useState(logom);

  useEffect(() => {
    $(document).ready(function ($) {
      $(window).scroll(function () {
        if ($(window).scrollTop() >= 20) {
          $(".menu_wrapper").addClass("back");
          $(".tel").addClass("scrollTel");

          // setLogo(logoo);
        } else {
          $(".menu_wrapper").removeClass("back");
          $(".tel").removeClass("scrollTel");
          // setLogo(logoo);
        }
      });
    });
  }, []);

  return (
    <div className="menu_wrapper">
      <div className="logo">
        <img src={logoo} alt="logomarca" />
      </div>
      <div className="nav"></div>
      <div className="contato">
        {/* <div className="circle_icon"><i class="fas fa-phone"></i></div> */}
        {/* <a href="https://web.whatsapp.com/send?phone=553132117760&text=Ol%C3%A1,%20quero%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20m%C3%B3dulo%20LGPD%20da%20MAV,%20preciso%20falar%20com%20o%20setor%20comercial" target="_blank" rel="noreferrer"> */}
        <div className="tel">
          {/* <div className="text"></div> */}
          entre em contato 
        </div>
        {/* </a> */}
      </div>
    </div>
  );
}
