import React from "react";

import "./style.css";

function Banner() {
  return (
    <div className="banner_wrapper">
      <div className="banner_container">
        <div className="content_head">
          <div className="headline">
            O e-mail é o vetor mais utilizado em ataques ransomware no mundo
          
          </div>
          <div className="headline_sub">Segundo relatório da Verizon, 94% dos ataques de ransomware tem como
            origem o e-mail.</div>
          <div className="subheadline">
            Os ataques utilizam principalmente de engenharia social para
            convencer o usuário a cair nesse golpe. É cada vez mais importante
            treinar o usuário sobre os perigos e ameaças que podem chegar por
            e-mail.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
