import React from "react";

import "./style.css";
import ScrollAnimation from "react-animate-on-scroll";
function Final() {
  return (
    <div className="final_wrapper">
      <div className="final_container">
      <ScrollAnimation animateIn="fadeIn">
        <div className="text">
          Para contratar acesse o Portal do Cliente ou entre em contato com
          nossa equipe de relacionamento utilizando o e-mail:
          relacionamento@mav.com.br ou o <a href={'https://web.whatsapp.com/send?phone=553132117760'}>WhatsApp: (31) 3211-7760</a>
        </div>
        </ScrollAnimation>
      </div>
    </div>
  );
}

export default Final;
