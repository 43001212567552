import React from "react";

import "./style.css";
import ScrollAnimation from "react-animate-on-scroll";
import example from '../../Assets/img/example.png'
function Tipos() {
  return (
    <div className="tipos_wrapper">
      <div className="tipos_container">
      <ScrollAnimation animateIn="fadeIn">
        <div className="title_tipos">
          Tipos de phishings que podem ser recebidos por e-mail:
        </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn">
        <div className="tipos_box_container">
          <div className="box">
            <div className="image_container">
              <i class="fas fa-lock"></i>
            </div>
            <div className="tipe_name">Alteração de senha de e-mail</div>
          </div>
          <div className="box">
                   <div className="image_container">
                   <i class="fas fa-money-check-alt"></i>
            </div>
            <div className="tipe_name">Banco</div>
          </div>
          <div className="box">
                   <div className="image_container">
                   <i class="fas fa-file-invoice"></i>
            </div>
            <div className="tipe_name">Boleto </div>
          </div>
          <div className="box">
                   <div className="image_container">
                   <i class="fas fa-money-check"></i>
            </div>
            <div className="tipe_name">CPF Sujo </div>
          </div>
          <div className="box">
                   <div className="image_container">
                   <i class="fas fa-file"></i>
            </div>
            <div className="tipe_name">Curículo </div>
          </div>
          <div className="box">
                   <div className="image_container">
                   <i class="fas fa-barcode"></i>
            </div>
            <div className="tipe_name">Nota fiscal eletrônica </div>
          </div>
          <div className="box">
                   <div className="image_container">
                   <i class="fas fa-tags"></i>
            </div>
            <div className="tipe_name">Ofertas e promoções </div>
          </div>
          <div className="box">
                   <div className="image_container">
                   <i class="fas fa-money-bill-alt"></i>
            </div>
            <div className="tipe_name">Receita federal </div>
          </div>
          <div className="box">
                   <div className="image_container">
                   <i class="far fa-dot-circle"></i>
            </div>
            <div className="tipe_name">Resgate de pontos </div>
          </div>
        </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn">
        <div className="image_example">
          <div className="title_example">Exemplo de e-mail enviado para o usuário:</div>
          <img src={example} alt="example"/>

        </div>
        </ScrollAnimation>
      </div>
    </div>
  );
}

export default Tipos;
